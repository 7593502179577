
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getRehab } from "@/api/rehab.api";
import { useStore } from "vuex";

export interface Data {
  isNexioEnabled: boolean;
}
export default defineComponent({
  name: "Payment Page",
  components: {},
  props: [],
  setup(props) {
    let organizationId = ref<string | null>("");
    const store = useStore();
    let data = reactive<Data>({
      isNexioEnabled: false
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      if (store.getters.selectedRehab.id) {
        await getRehab(store.getters.selectedRehab.id).then(rehabResult => {
          data.isNexioEnabled = rehabResult.nexioPermission;
        });
      }
    });

    return {
      organizationId,
      data,
    };
  },
});
